import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const fullConfig = resolveConfig(tailwindConfig)

export const SCREEN_SIZE_SM = 'sm'
export const SCREEN_SIZE_MD = 'md'
export const SCREEN_SIZE_LG = 'lg'
export const SCREEN_SIZE_XL = 'xl'
export const SCREEN_SIZE_XXL = '2xl'

// These functions only work client side! Don't use in server code!
const parseSize = (size) => parseInt(size.slice(0, size.length - 2), 10)

export const getScreenSize = () => {
    const { screens } = fullConfig.theme
    const { md, lg, xl } = screens
    const xxl = screens['2xl']

    const SIZES = {
        [SCREEN_SIZE_MD]: md,
        [SCREEN_SIZE_LG]: lg,
        [SCREEN_SIZE_XL]: xl,
        [SCREEN_SIZE_XXL]: xxl,
    }

    let currentScreenSize = SCREEN_SIZE_SM
    Object.keys(SIZES).forEach((key) => {
        const size = parseSize(SIZES[key])
        if (window.innerWidth >= size) {
            currentScreenSize = key
        }
    })

    return currentScreenSize
}
