import { twMerge } from 'tailwind-merge'
import Icon from './icon'

type AvatarProps = {
    imageUrl: string | null
    iconName?: string
    iconClassName?: string
    className?: string
}

export function Avatar({
    imageUrl = null,
    iconName = 'user',
    iconClassName,
    className,
    ...props
}: AvatarProps) {
    return (
        <span
            className={twMerge(
                'inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100 p-2 bg-cover',
                className
            )}
            style={{
                backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
            }}
            {...props}
        >
            {imageUrl === null && (
                <Icon
                    name={iconName}
                    className={twMerge('h-full w-full', iconClassName)}
                />
            )}
        </span>
    )
}

export function AvatarLoading() {
    return <div className="h-10 w-10 bg-gray-200 rounded-full skeleton" />
}
