'use client'

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'
import { variant as createVariant } from '@connections/utils'

export default function Collapsable({
    icon,
    header,
    isExpanded,
    onExpand,
    disabled = false,
    variant = 'default',
    testId = 'CollapsableHeader',
    className,
    children,
}) {
    const [isCollapsing, setIsCollapsing] = useState(false)
    const handleCollapseStart = () => setIsCollapsing(true)
    const handleCollapseComplete = () => setIsCollapsing(false)

    return (
        <>
            <button
                tabIndex={0}
                type="button"
                disabled={disabled}
                onClick={onExpand}
                onKeyDown={onExpand}
                className={twMerge(
                    'w-full flex flex-row items-center justify-between',
                    createVariant(variant, {
                        default: 'mb-4',
                        outlined: `border border-gray-100 p-2.5 ${
                            isExpanded ? 'rounded-t-md' : 'rounded-md'
                        }`,
                    }),
                    className
                )}
                data-testid={testId}
            >
                {header}
                <motion.div
                    animate={isExpanded ? 'open' : 'closed'}
                    variants={{
                        open: { rotate: 180 },
                        closed: { rotate: 0 },
                    }}
                >
                    {icon}
                </motion.div>
            </button>
            <AnimatePresence initial={false}>
                {isExpanded && (
                    <motion.div
                        key="content"
                        animate="open"
                        exit="collapsed"
                        initial="collapsed"
                        transition={{ duration: 0.3 }}
                        data-testid="CollapsableContent"
                        className={twMerge(
                            createVariant(variant, {
                                outlined:
                                    'border border-gray-100 px-2 py-2.5 rounded-b-md border-t-0',
                            }),
                            isCollapsing && 'overflow-hidden'
                        )}
                        variants={{
                            open: { opacity: 1, height: 'auto' },
                            collapsed: { opacity: 0, height: 0 },
                        }}
                        onAnimationStart={handleCollapseStart}
                        onAnimationComplete={handleCollapseComplete}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}
