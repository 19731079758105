module.exports = {
    content: [
        './src/pages/**/*.js',
        './src/components/**/*.js',
        './src/util/**/*.js',
        './src/**/*.tsx',
        './src/**/*.ts',
    ],
    plugins: [
        require('@tailwindcss/forms'),
        require('@tailwindcss/typography'),
    ],
    theme: {
        extend: {
            colors: {
                white: {
                    DEFAULT: 'rgb(var(--color-white) / <alpha-value>)', // #FFF
                },
                black: {
                    DEFAULT: 'rgb(var(--color-black) / <alpha-value>)', // #0F1B3D
                },
                gray: {
                    800: 'rgb(var(--color-gray-800) / <alpha-value>)', // #273250
                    600: 'rgb(var(--color-gray-600) / <alpha-value>)', // #575F77
                    400: 'rgb(var(--color-gray-400) / <alpha-value>)', // #878D9E
                    200: 'rgb(var(--color-gray-200) / <alpha-value>)', // #B7BBC5
                    150: 'rgb(var(--color-gray-150) / <alpha-value>)', // #CDCDCD
                    100: 'rgb(var(--color-gray-100) / <alpha-value>)', // #E7EDEE
                    50: 'rgb(var(--color-gray-50) / <alpha-value>)', // #F9F9F9
                },
                primary: {
                    DEFAULT: 'rgb(var(--color-primary) / <alpha-value>)', // #EC0B2D
                },
                'primary-dark': {
                    DEFAULT: 'rgb(var(--color-primary-dark) / <alpha-value>)', // #CD0A27
                },
                blue: {
                    DEFAULT: 'rgb(var(--color-blue) / <alpha-value>)', // #08559D
                },
                'blue-gray': {
                    DEFAULT: 'rgb(var(--color-blue-gray) / <alpha-value>)', // #92aec7
                },
                'blue-bright': {
                    DEFAULT: 'rgb(var(--color-blue-bright) / <alpha-value>)', // #006FD6
                },
                'blue-light': {
                    DEFAULT: 'rgb(var(--color-blue-light) / <alpha-value>)', // #F2F8FD
                },
                green: {
                    DEFAULT: 'rgb(var(--color-green) / <alpha-value>)', // #39C276
                },
                yellow: {
                    DEFAULT: 'rgb(var(--color-yellow) / <alpha-value>)', // #FEC82B
                },
            },
            fontSize: {
                '2xs': ['10px', '16px'],
                xs: ['12px', '16px'],
                sm: ['14px', '18px'],
                base: ['16px', '24px'],
                lg: ['20px', '24px'],
                '2xl': ['24px', '32px'],
                '3xl': ['24px', '32px'],
                '4xl': ['28px', '32px'],
                '5xl': ['48px', '48px'],
                '6xl': ['60px', '56px'],
            },
            boxShadow: {
                card: '0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03)',
            },
            outline: {
                'default-input': '2px solid rgba(var(--color-blue-bright))',
                default: ['2px solid rgba(var(--color-primary))', '1px'],
            },
            maxHeight: {
                '4/5': '80%',
                '95v': '95vh',
            },
            minWidth: {
                56: '14rem',
            },
            typography: {
                DEFAULT: {
                    css: {
                        h3: {
                            color: 'rgb(var(--color-blue))',
                            'font-weight': 500,
                            'font-size': '1.5rem',
                            'margin-bottom': '1.5rem',
                            'padding-left': '1.25rem',
                            'border-left': '4px',
                            'border-style': 'solid',
                            'border-color': 'rgb(var(--color-blue))',
                        },
                        h4: {
                            color: 'rgb(var(--color-black))',
                            'font-size': '1.5rem',
                            'font-weight': 500,
                            'margin-bottom': '1rem',
                        },
                        h5: {
                            color: 'rgb(var(--color-gray-600))',
                            'font-weight': 500,
                            'text-transform': 'uppercase',
                            'margin-bottom': '0.75rem',
                        },
                        h6: {
                            color: 'rgb(var(--color-blue))',
                            'font-weight': 500,
                            'text-transform': 'uppercase',
                            'margin-bottom': '0.75rem',
                        },
                        'ul li': {
                            '&:marker': {
                                'background-color':
                                    'rgb(var(--color-gray-800))',
                            },
                            p: {
                                margin: 0,
                            },
                            'ul li': {
                                '&:marker': {
                                    'background-color': 'transparent',
                                    border: 'solid 1px',
                                    'border-color': 'rgb(var(--color-black))',
                                },
                                'li:marker': {
                                    'background-color':
                                        'rgb(var(--color-gray-800))',
                                    'border-radius': 0,
                                    border: 0,
                                },
                            },
                        },
                        'ol li': {
                            p: {
                                margin: 0,
                            },
                        },
                        'hr, blockquote, img': {
                            margin: '2.25rem 0',
                        },
                        'ul, ol, p': {
                            'margin-top': 0,
                            'margin-bottom': '2.25rem',
                        },
                        'ul > li > *, ol > li > *': {
                            '&:first-child': {
                                margin: 0,
                            },
                            '&:last-child': {
                                margin: 0,
                            },
                        },
                        a: {
                            color: 'rgb(var(--color-blue-bright))',
                        },
                    },
                },
            },
            animation: {
                'spin-slow': 'spin 2.5s linear infinite',
            },
            transitionProperty: {
                spacing: 'margin, padding',
                height: 'height',
            },
        },
        borderWidth: {
            DEFAULT: '1px',
            0: '0',
            2: '2px',
            3: '3px',
            4: '4px',
            6: '6px',
            8: '8px',
        },
        zIndex: {
            '-1': '-1',
            0: 0,
            1: 1,
            10: 10,
            20: 20,
            30: 30,
            40: 40,
            50: 50,
            sidebar: 40,
            header: 50,
            'modal-bg': 60,
            modal: 70,
            popover: 75,
            'progress-bar': 80,
            auto: 'auto',
        },
        fontFamily: {
            sans: 'var(--font-dm-sans), Helvetica, Arial, sans-serif',
            accent: 'var(--font-caveat), Caveat-fallback, Caveat-fallback-2, Caveat-fallback-3, serif',
        },
        lineClamp: {
            4: '4',
            8: '8',
        },
        scale: {
            '-100': '-1',
        },
    },
    variants: {
        extend: {},
    },
    corePlugins: {
        container: false,
    },
}
