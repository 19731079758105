'use client'

import React, { useRef, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const THUMB_WIDTH = 22

export function Slider({
    min = 0,
    max = 1,
    disabled,
    formatValue = null,
    value: originalValue = 0,
    onChange,
    className,
}) {
    const thumbRef = useRef()
    const containerRef = useRef()
    const [position, setPosition] = useState(null)
    const [value, setValue] = useState(originalValue)
    const [isDragging, setIsDragging] = useState(false)
    const [relativeCursorPosition, setRelativeCursorPosition] = useState(null)

    const stopDefaultEvents = (event) => {
        event.stopPropagation()
        event.preventDefault()
    }

    const onMouseDown = (event) => {
        if (disabled) {
            return
        }
        stopDefaultEvents(event)
        // only left mouse button
        if (event.button !== 0) return
        setIsDragging(true)
        setRelativeCursorPosition(event.pageX - thumbRef.current.offsetLeft)
    }

    const onTouchStart = (event) => {
        if (disabled) {
            return
        }
        stopDefaultEvents(event)
        setIsDragging(true)
        setRelativeCursorPosition(
            event.targetTouches[0].pageX - thumbRef.current.offsetLeft
        )
    }

    const onThumbMove = (newPosition) => {
        if (
            newPosition >= -THUMB_WIDTH / 2 &&
            newPosition <= containerRef.current.clientWidth - THUMB_WIDTH / 2
        ) {
            setPosition(newPosition)
            const newValue =
                ((newPosition + THUMB_WIDTH / 2) /
                    containerRef.current.clientWidth) *
                    (max - min) +
                min
            setValue(newValue)
            onChange(newValue)
        }
    }

    const onMouseMove = (event) => {
        stopDefaultEvents(event)
        if (!isDragging) return
        const newPosition = event.pageX - relativeCursorPosition
        onThumbMove(newPosition)
    }

    const onTouchMove = (event) => {
        stopDefaultEvents(event)
        if (!isDragging) return
        const newPosition =
            event.targetTouches[0].pageX - relativeCursorPosition
        onThumbMove(newPosition)
    }

    const onThumbStop = (event) => {
        stopDefaultEvents(event)
        setIsDragging(false)
    }

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('mousemove', onMouseMove)
            containerRef.current.addEventListener('mouseup', onThumbStop)
            containerRef.current.addEventListener('touchmove', onTouchMove)
            containerRef.current.addEventListener('touchend', onThumbStop)
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener(
                    'mousemove',
                    onMouseMove
                )
                containerRef.current.removeEventListener('mouseup', onThumbStop)
                containerRef.current.removeEventListener(
                    'touchmove',
                    onTouchMove
                )
                containerRef.current.removeEventListener(
                    'touchend',
                    onThumbStop
                )
            }
        }
    }, [isDragging, containerRef.current])

    useEffect(() => {
        if (containerRef.current && position === null) {
            setPosition((value / max) * containerRef.current.clientWidth)
        }
    }, [containerRef.current])

    return (
        <div
            ref={containerRef}
            style={{ height: THUMB_WIDTH }}
            className={twMerge('relative w-full', className)}
        >
            {isDragging && (
                <div
                    style={{
                        left: position + THUMB_WIDTH * 1.5,
                        top: THUMB_WIDTH,
                    }}
                    className="absolute rounded-sm shadow-md px-3 py-1 opacity-100 bg-white z-1"
                >
                    <p className="text-sm">
                        {formatValue === null ? value : formatValue(value)}
                    </p>
                </div>
            )}
            <div
                style={{ top: THUMB_WIDTH / 2 - 1 }}
                className="absolute w-full h-0.5 bg-gray-200"
            />
            <div
                style={{
                    width: position,
                    top: THUMB_WIDTH / 2 - 1,
                }}
                className={twMerge(
                    'absolute h-0.5',
                    disabled ? 'bg-gray-500' : 'bg-blue-bright'
                )}
            />
            <button
                type="button"
                role="slider"
                aria-valuemax={max}
                aria-valuemin={min}
                aria-valuenow={value}
                ref={thumbRef}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
                style={{
                    width: THUMB_WIDTH,
                    height: THUMB_WIDTH,
                    left: position,
                }}
                className={twMerge(
                    'absolute rounded-full',
                    disabled
                        ? 'cursor-not-allowed bg-gray-300 bg-opacity-50'
                        : 'cursor-pointer bg-blue-bright'
                )}
            />
        </div>
    )
}
